import React, { Suspense } from "react";
import styled from "styled-components";
import { AuthProvider, useAuth } from "./hooks/usePassword";

const Weekdays = React.lazy(() => import("./components/Weekdays"));
const Weather = React.lazy(() => import("./components/Weather"));
const Auth = React.lazy(() => import("./components/Auth"));
const NoStreik = React.lazy(() => import("./components/NoStreik"));

const App = () => {
  return (
    <AuthProvider>
      <Container>
        <TopContainer>
          <Image src="logo.png" alt="logo" />
          <WeatherContainer>
            <Suspense fallback={null}>
              <Weather />
            </Suspense>
          </WeatherContainer>
        </TopContainer>
        <BottomContainer>
          <Content />
        </BottomContainer>
      </Container>
    </AuthProvider>
  );
};

const Content = () => {
  return (
    <Suspense fallback={null}>
      <NoStreik />
    </Suspense>
  );

  // const { isAuthenticated } = useAuth();

  // if (!isAuthenticated) {
  //   return (
  //     <Suspense fallback={null}>
  //       <Auth />
  //     </Suspense>
  //   );
  // }
  // return (
  //   <Suspense fallback={null}>
  //     <Weekdays />
  //   </Suspense>
  // );
};

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
`;
const TopContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 24px;
`;
const BottomContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Image = styled.img`
  width: 50%;
  max-width: 800px;
  min-width: 360px;
  padding-top: 8px;
  padding-bottom: 32px;
`;
const WeatherContainer = styled.div`
  height: 28px;
`;

export default App;
