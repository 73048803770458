import { createContext, useContext, useLayoutEffect, useState } from "react";
import md5 from "md5";

const encryptedKey = "f8da9440d8b0c1cadcb7299a6bf1bc6a";

export type AuthProps = {
  isAuthenticated;
  setPassword;
};

const AuthContext = createContext<AuthProps | null>(null);

export function AuthProvider({ children }: { children: any }) {
  const [password, setPassword] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useLayoutEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const pw = params.get("passord");
    if (pw) setPassword(pw);
  }, []);

  useLayoutEffect(() => {
    if (password === "") return;
    if (md5(password.toLowerCase()) === encryptedKey) setIsAuthenticated(true);
  }, [password]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = (): AuthProps => {
  const ctx = useContext(AuthContext);

  if (!ctx) {
    throw Error(
      "The `useAuth` hook must be called from a descendent of the `AuthProvider`."
    );
  }

  return { ...ctx };
};
